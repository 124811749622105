// FORM VALIDATION
.invalid-label {
  color: $form-danger-color;
  font-size: .75rem;
  margin-top: .25rem;
  text-align: left;

  .field-invalid & {
    display: block;
  }
}

.field-invalid {
  .uk-input,
  .uk-radio,
  .uk-checkbox {
    border-color: $form-danger-color !important;
  }
}
// end of FORM VALIDATION

.highlighted-primary {
  color: $global-primary-background;
}

.highlighted-secondary {
  color: white !important;
}

.uk-box-shadow-large--top {
  box-shadow: 0 -14px 25px rgba(0,0,0,0.16);
}

// EXPAND IMAGES ON EXTRA LARGE SCREENS (FIX)
.expand-fix {
  @media screen and (min-width: 1921px) {
    margin: 0 auto;
    width: 100% !important;
  }
}
// end of EXPAND IMAGES ON EXTRA LARGE SCREENS (FIX)

// MOBILE CALL BUTTON
#call {
  animation: pulse 1.5s infinite;
  background-color: $global-primary-background;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba($global-primary-background, 0.4);
  color:white;
  line-height: 54px;
  height: 54px;
  width: 54px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($global-primary-background, .4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba($global-primary-background, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($global-primary-background, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($global-primary-background, .4);
    box-shadow: 0 0 0 0 rgba($global-primary-background, .4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba($global-primary-background, 0);
    box-shadow: 0 0 0 20px rgba($global-primary-background, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba($global-primary-background, 0);
    box-shadow: 0 0 0 0 rgba($global-primary-background, 0);
  }
}
//end of MOBILE CALL BUTTON

// THEME SWITCH
.uk-grid {
  &.theme-toggle-target:not(.uk-background-secondary) {
    background-color: #fafafa;
  }
}

.uk-navbar-container {
  &.theme-toggle-target:not(.uk-background-secondary) {
    box-shadow: $global-medium-box-shadow;
  }
}
// end of THEME SWITCH

// reCAPCTHA
.grecaptcha-badge {
  //visibility: hidden;
}
// enf of reCAPCTHA
