// TOOLS CARD STYLES
// =======================================
.tools-wrapper {
  overflow: hidden;
  margin: 0 -15px -15px !important;
  //padding-bottom: 60px;
}

@media screen and (min-width: 960px) {
  .tools-wrapper {
    height: calc(100vh - 110px);
    margin: 0;
    position: relative;

    .uk-card-body {
      height: calc(100vh - 140px);
      padding-bottom: 0;
      padding-top: 0;
      position: relative;
      top: 15px;
    }
  }
}
// =======================================
// end of TOOLS CARD STYLES
