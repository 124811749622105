#galleryFilter {
  li {
    box-shadow: none;
    transition: box-shadow .15s ease-in-out;

    a {
      display: block;
      overflow: hidden;
    }

    img {
      transform: scale(1);
      transition: all .15s ease-in-out;
    }

    &:hover {
      //box-shadow: 0 24px 48px rgba(0, 0, 0, 1);
      position: relative;
      z-index: 1;

      img {
        //box-shadow: 6px 12px 24px rgba(0, 0, 0, .25);
        transform: scale(1.1);
      }
    }
  }
}

header {
  margin-top: -80px;
}

.uk-sticky {
  &:not(.theme-toggle-target).uk-active {
    background-color: #000;
  }
}

// MODEL PREVIEW
// =======================================
.model-preview {
  .uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
    stroke: #e8e8e8 !important;
  }
}

.animation-rotate {
  animation: rotation 48s infinite linear;
}

.animation-rotate-inverse {
  animation: rotation-inverse 84s infinite linear;
}

.animation-rotate-inverse-slow {
  animation: rotation-inverse 30s infinite linear;
}

.animation-pulse-color {
  //animation: color 2s infinite linear;
}

@-webkit-keyframes color {
  0% {
    fill: white;
  }
  25% {
    fill: $global-primary-background;
    stroke-width: 3px;
  }
  50% {
    fill: white;
  }
  75% {
    fill: white;
  }
  100% {
    fill: white;
  }
}
@keyframes color {
  0% {
    fill: white;
  }
  25% {
    fill: $global-primary-background;
    stroke-width: 2px;
  }
  50% {
    fill: white;
  }
  75% {
    fill: white;
  }
  100% {
    fill: white;
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes rotation-inverse {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes rotation-inverse {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
// =======================================
// end of MODEL PREVIEW
