// MODEL STYLES
// =======================================
.model-wrapper {
  outline: none;

  @media screen and (max-width: $breakpoint-medium-max) {
    margin-bottom: 15px !important;
  }

  .uk-slideshow-items {
    min-height: unset !important;
    padding-bottom: 250%;
  }
}

.model {
  display: block;
  fill-rule: evenodd;
  height: 100%;
  margin: 0 auto;
  padding: 15px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all .1s ease-in-out;
  width: auto;

  [data-uk-filter-control] {
    fill: rgba($global-primary-background, .1);
  }
}

.model-nav {
  > li {
    pointer-events: none;

    @media screen and (max-width: $breakpoint-medium-max) {
      &:nth-child(2) {
        width: 100%;
      }
    }

    button {
      pointer-events: auto;
    }
  }
}

.model__area {
  cursor:  pointer;
  fill: transparent;
  stroke: rgba($global-color, .1);
  stroke-width: 1px;
  transition: all $global-transition-duration $global-transition-function;

  .uk-light & {
    stroke: rgba(255, 255, 255, .1);
  }

  &:hover,
  &.is-highlighted,
  &.uk-active {
    cursor: pointer;
    stroke: $global-color;

    .uk-light & {
      stroke: rgba(255, 255, 255, 1);
    }
  }

  &:hover {
    fill: rgba($global-color, .1);

    .uk-light & {
      fill: rgba(white, .1);
    }
  }

  &:hover:not(.is-highlighted),
  &:hover:not(.uk-active) {
    fill: rgba($global-color, .1);

    .uk-light & {
      fill: rgba(white, .1);
    }
  }

  &.is-highlighted,
  &.uk-active {
    cursor: default;
    fill: $global-primary-background !important;
  }
}

.model__outline {
  fill: none;
  stroke: rgba($global-color, .5);
  stroke-width: 1px;
  transition: all $global-transition-duration $global-transition-function;

  .uk-light & {
    stroke: rgba(255, 255, 255, .5);
  }
}

@media screen and (min-width: $breakpoint-large) {
  .model-wrapper {
    height: calc(100vh - 110px);
    margin-right: 15px;

    .uk-slideshow-items {
      height: 100%;
      padding-bottom: 0;

      > li {
        transition-duration: .2s !important;
        overflow: auto;
      }
    }
  }

  .model {
    width: 100%;

    .is-zoomed & {
      height: auto;
      max-height: 180vh;
      //height: 200vh;
      //width: auto;
    }
  }

  .model-nav {
    > li {
      margin-right: 15px;
      width: auto;
    }
  }
}
// =======================================
// end of MODEL STYLES

// PAIN
// =======================================
.model__area {
  //@media screen and (min-width: $breakpoint-large) {
  //  &[data-pain="100"]:hover {
  //    fill: #eb543a;
  //  }
  //
  //  &[data-pain="75"]:hover {
  //    fill: #ff8f45;
  //  }
  //
  //  &[data-pain="50"]:hover {
  //    fill: #fbc160;
  //  }
  //
  //  &[data-pain="25"]:hover {
  //    fill: #01b8c4;
  //  }
  //}

  .pain-view & {
    &[data-pain="100"] {
      fill: #eb543a;
    }

    &[data-pain="75"] {
      fill: #ff8f45;
    }

    &[data-pain="50"] {
      fill: #fbc160;
    }

    &[data-pain="25"] {
      fill: #01b8c4;
    }

    &:hover {
      fill: rgba($global-color, .1);

      .uk-light & {
        fill: rgba(white, .1);
      }
    }
  }
}
// =======================================
// end of PAIN


